export const SET_START_WEIGHT = "SET_START_WEIGHT";
export const SET_GOAL_WEIGHT = "SET_GOAL_WEIGHT";
export const SET_WEEKLY_CHANGE = "SET_WEEKLY_CHANGE";
export const SET_DAILY_KCAL_CHANGE = "SET_DAILY_KCAL_CHANGE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_WEEKS_FOR_AVG = "SET_WEEKS_FOR_AVG";
export const TOGGLE_MEASUREMENT_SYSTEM = "TOGGLE_MEASUREMENT_SYSTEM";

export const SET_KCAL_AND_KG = "SET_KCAL_AND_KG";
export const SET_WEEKLY_KCAL_AND_KG = "SET_WEEKLY_KCAL_AND_KG";
export const SET_WEEKLY_TDEE = "SET_WEEKLY_TDEE";
export const SET_AVG_TDEE = "SET_AVG_TDEE";

export const ADD_ANOTHER_WEEK = "ADD_ANOTHER_WEEK";
export const LOCK_WEEK = "LOCK_WEEK";

export const TOGGLE_COMPACT_VIEW = "TOGGLE_COMPACT_VIEW";

// AUTH
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT = "SET_AUTH_REDIRECT";
